import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import BlogRollTemplate from '../../components/BlogRoll';

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const creativeTitle = "I AM CREATIVE";
const creativeDescription = "I am Studio is a full service creative agency. We offer Art Direction & Conception, Branding & Identity, Project Management, Fine Drafting, 3-D Modeling, Video & Photo-Production and Motion Graphics.";

export default function CreativeIndexPage({ location }) {
  if (location?.state?.filter) {
    var detailFilter = location.state.filter;
  }
  return (
    <Layout>
      <Helmet titleTemplate={creativeTitle}>
        <title>{creativeTitle}</title>
        <meta name="info" content={creativeDescription} />
        <meta name="description" content={creativeDescription} />
        <meta property="og:title" content={creativeTitle} />
        <meta property="og:description" content={creativeDescription} />
      </Helmet>
      <StaticQuery
        query={graphql`
          query CreativeBlogRollQuery {
            allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: {
                frontmatter: {
                  templateKey: { eq: "portfolio-post" }
                  category: { eq: "creative" }
                }
              }
            ) {
              edges {
                node {
                  excerpt(pruneLength: 400)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    tags
                    templateKey
                    date(formatString: "MMMM YYYY")
                    admin {
                      backgroundcolor
                      featuredimage
                      featuredimage2
                      featuredpost
                      hiddenpost
                      nonparallaxpost
                    }
                  }
                }
              }
            }
            markdownRemark(fields: { slug: { eq: "/utils/creativeOrder/" } }) {
              frontmatter {
                creativeOrder {
                  creativeItem
                }
                category
              }
            }
          }
        `}
        render={(data) => (
          <BlogRollTemplate
            data={data}
            category="CREATIVE"
            detailFilter={detailFilter}
          />
        )}
      />
    </Layout>
  );
}
